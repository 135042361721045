/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { makeHandle as n } from "../core/handleUtils.js";
let o, e;
const t = globalThis.esriConfig?.locale ?? globalThis.dojoConfig?.locale;
function i() {
  return t ?? globalThis.navigator?.language ?? "en";
}
function c() {
  return void 0 === e && (e = i()), e;
}
function r(n) {
  o = n || void 0, d();
}
function a(n = c()) {
  const o = /^([a-zA-Z]{2,3})(?:[_-]\w+)*$/.exec(n);
  return o?.[1].toLowerCase();
}
const s = {
  he: !0,
  ar: !0
};
function l(n = c()) {
  const o = a(n);
  return void 0 !== o && (s[o] || !1);
}
const u = [];
function f(o) {
  return u.push(o), n(() => u.splice(u.indexOf(o), 1));
}
const g = [];
function h(o) {
  return g.push(o), n(() => g.splice(g.indexOf(o), 1));
}
function d() {
  const n = o ?? i();
  e !== n && ([...g].forEach(o => o(n)), e = n, [...u].forEach(o => o(n)));
}
globalThis.addEventListener?.("languagechange", d);
export { h as beforeLocaleChange, i as getDefaultLocale, a as getLanguage, c as getLocale, f as onLocaleChange, l as prefersRTL, r as setLocale };